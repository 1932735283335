import { useEffect, startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from '.';

export function useChangeLanguage(locale: SupportedLanguage) {
    const { i18n } = useTranslation();
    useEffect(() => {
        if (i18n && i18n['changeLanguage']) {
            startTransition(() => {
                // Fixes the error: "This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering."
                i18n.changeLanguage(locale);
            });
        }
    }, [locale, i18n]);
}
