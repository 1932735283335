import { Link, NavLink, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    ArrowLeftEndOnRectangleIcon,
    ArrowLeftIcon,
} from '@heroicons/react/24/outline';
import { usePrelineHs } from '~/hooks/use-preline-hs.hook';
import { SupportedLanguage } from '~/i18n';
import { cn } from '~/lib/util';
import { useFetcherWithPromise } from '~/lib/util/util.fetcher';
import { paths } from '~/paths';
import DropdownIcon from './ui/svg/dropdown-icon';
import Ready2OrderLogo from './ui/svg/ready2order-logo';

export default function Navbar({
    href,
    label,
}: {
    href: string;
    label: string;
}) {
    const { t, i18n } = useTranslation();
    usePrelineHs();
    const navigate = useNavigate();
    const fetcher = useFetcherWithPromise();
    const currentLanguage = i18n.language;

    const navLinkClass = (isActive: boolean) => {
        return cn('flex py-2 text-sm font-medium', {
            'border-b-2 font-bold border-black': isActive,
        });
    };

    const changeLanguage = (lng: SupportedLanguage) => {
        // change language client side
        i18n.changeLanguage(lng);
        // call route to change the language server side and set cookie
        fetcher.submit(
            {
                lng: lng,
            },
            {
                method: 'GET',
                action: paths.change_language(),
            }
        );
    };

    const Seperator = () => (
        <div className='mx-6 border-r border-gray-500 w-[1px] h-[50%]' />
    );

    const logout = () => {
        navigate(paths.logout());
    };

    return (
        <nav
            className='max-w-7xl flex basis-full items-center w-full mx-auto px-4 sm:px-6 lg:px-8'
            aria-label='Global'
        >
            <div className='me-5 md:me-8'>
                <Link
                    to={paths.root()}
                    className='flex-none text-xl font-semibold dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600'
                    aria-label='Brand'
                >
                    <div className='flex items-center gap-2'>
                        <Ready2OrderLogo />
                        <span className='font-medium'></span>
                    </div>
                </Link>
            </div>

            <div className='w-full h-full flex items-center justify-end'>
                <div className='flex'>
                    <div className='snap-center shrink-0 pe-5 sm:pe-8 sm:last:pe-0'>
                        <NavLink
                            to={paths.subscriptions()}
                            className={({ isActive }) => navLinkClass(isActive)}
                        >
                            {t('nav.subscriptions')}
                        </NavLink>
                    </div>
                    <div className='snap-center'>
                        <NavLink
                            to={paths.billing()}
                            className={({ isActive }) => navLinkClass(isActive)}
                        >
                            {t('nav.billing')}
                        </NavLink>
                    </div>
                </div>

                <Seperator />

                <div className='flex flex-row items-center justify-end'>
                    <div className='hs-dropdown relative inline-flex'>
                        <button
                            id='hs-dropdown-default-change-language'
                            className='hs-dropdown-toggle py-3 inline-flex items-center gap-x-2 text-sm font-medium   text-gray-800  hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
                            type='button'
                            aria-haspopup='menu'
                            aria-expanded='false'
                            aria-label='Dropdown'
                        >
                            {currentLanguage.toLocaleUpperCase()}
                            <DropdownIcon classes='hs-dropdown-open:rotate-180 size-4' />
                        </button>

                        <div
                            className='hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 z-50 hidden min-w-60 bg-white shadow-md rounded-lg p-1 space-y-0.5 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full'
                            role='menu'
                            aria-orientation='vertical'
                            aria-labelledby='hs-dropdown-default'
                        >
                            {Object.values(SupportedLanguage).map((lng) => {
                                return (
                                    <button
                                        key={`language-${lng}`}
                                        onClick={() => changeLanguage(lng)}
                                        disabled={i18n.language === lng}
                                        className={cn(
                                            'flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700',
                                            i18n.language === lng &&
                                                'bg-r2o-blue text-white hover:bg-r2o-blue'
                                        )}
                                    >
                                        {t(`nav.language.${lng}`)}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <Seperator />

                <div className='flex gap-4'>
                    <a
                        href={href}
                        className='py-1 px-4 inline-flex items-center gap-x-1 text-sm font-normal rounded-full border text-white bg-r2o-blue hover:bg-r2o-hoverBlue border-none focus:outline-none disabled:opacity-50 disabled:pointer-events-none'
                    >
                        <ArrowLeftIcon className='size-4' />
                        {label}
                    </a>
                    <button
                        type='button'
                        onClick={logout}
                        className='py-1 px-4 inline-flex items-center gap-x-1 text-sm font-medium rounded-full border border-red-500 text-red-500 hover:text-gray-50 hover:bg-red-500 focus:outline-none disabled:opacity-50 disabled:pointer-events-none'
                    >
                        <ArrowLeftEndOnRectangleIcon height={20} />
                        {t('logout.logout')}
                    </button>
                </div>
            </div>
        </nav>
    );
}
