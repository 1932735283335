import { createContext, PropsWithChildren, useContext } from 'react';

type AppContextType = {
    environment: string;
    appVersion: string;
};

const AppContext = createContext<AppContextType | null>(null);

const AppProvider = ({
    children,
    environment,
    appVersion,
}: PropsWithChildren<AppContextType>) => {
    return (
        <AppContext.Provider
            value={{
                environment: environment,
                appVersion: appVersion,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const ctx = useContext(AppContext);
    if (!ctx) {
        // throwing here is a nice touch because this is a developer error
        // and if this happened during production, something must have really broken
        throw new Error(
            'useAppContext can only be used in a AppContext-Provider tree'
        );
    }
    return ctx;
};

export default AppProvider;
