export default function Ready2OrderLogo() {
    return (
        <svg
            width='204px'
            height='35px'
            viewBox='0 0 204 35'
        >
            <g
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'
            >
                <path
                    d='M-2.41379348e-06,17.1056042 C-2.41379348e-06,27.5400323 5.98696471,34.2112246 15.2924221,34.2112246 C17.0371952,34.2112246 18.6109122,33.9717457 19.9793623,33.5954216 C18.9872364,32.6717183 18.1661665,31.5769601 17.5161527,30.4479876 C16.8319282,30.550621 16.0450692,30.6532545 15.2924219,30.6532545 C6.77382312,30.6532545 3.7632344,23.6057405 3.7632344,17.1056043 C3.7632344,10.6054682 6.77382336,3.55795252 15.2924222,3.55795252 C21.9636144,3.55795252 24.392612,7.56066802 24.392612,10.2975663 C24.392612,12.8634093 23.8452329,13.9581698 22.3741482,16.1476887 C20.7320085,18.5082648 19.7398837,20.3214607 19.7398837,23.9136404 C19.7398837,29.0453264 24.1189213,34.2112246 32.6033106,34.2112246 C41.9087673,34.2112246 47.8957327,27.5400321 47.8957327,17.1056039 C47.8957327,6.67117584 41.9087673,-1.65344828e-05 32.6033106,-1.65344828e-05 C30.8585374,-1.65344828e-05 29.2848192,0.239462276 27.9163703,0.615786017 C28.9084951,1.53948948 29.7295649,2.63424929 30.3795799,3.76322003 C31.0638044,3.66058628 31.8506623,3.55795252 32.6033107,3.55795252 C41.1219072,3.55795252 44.1325005,10.6054678 44.1325005,17.1056038 C44.1325005,23.6057399 41.1219072,30.6532541 32.6033107,30.6532541 C25.9321184,30.6532541 23.5031208,26.6505406 23.5031208,23.9136403 C23.5031208,21.3477973 24.0504999,20.2530368 25.5215824,18.0635179 C27.1295125,15.7029429 28.1558492,13.8897471 28.1558492,10.2975662 C28.1558492,5.16588076 23.7768092,-1.65344828e-05 15.2924223,-1.65344828e-05 C5.98696483,-1.65344828e-05 -2.41379348e-06,6.6711756 -2.41379348e-06,17.1056042 Z'
                    fill='#1493BE'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M62.3435382,13.1474938 C63.0311236,11.5263722 64.4870798,10.3989502 66.2195073,10.3989502 C66.6862783,10.3989502 67.1261162,10.3989502 67.8675585,10.4815311 L67.7023966,12.9284726 C67.0435353,12.872819 66.6036928,12.872819 66.1351309,12.872819 C64.3775703,12.872819 63.0023996,14.0541014 62.3435382,16.1150621 L62.3435382,23.590529 L59.8696682,23.590529 L59.8696682,10.6197657 L62.3435382,10.6197657 L62.3435382,13.1474938 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M80.4564706,21.8595376 C78.670186,23.2885653 77.0759918,23.8379172 74.7403552,23.8379172 C71.1947191,23.8379172 68.5556825,21.8595376 68.5556825,17.2151964 C68.5556825,12.4326207 71.2503715,10.3716601 74.8498693,10.3716601 C78.4493716,10.3716601 80.5390561,12.6247145 80.6216325,16.6658479 C80.6216325,17.0231036 80.6216325,17.2977784 80.5390561,17.7914757 L70.9200431,17.7914757 C71.1408575,20.7321143 72.7063277,21.8595376 74.8498693,21.8595376 C76.582297,21.8595376 77.9000197,21.4196997 79.5498618,20.2653457 L80.4564706,21.8595376 Z M78.4224385,15.8687509 C78.2303435,13.5869736 76.9664823,12.3787626 74.8498693,12.3787626 C72.8984181,12.3787626 71.3329526,13.3679524 70.9756956,15.8687509 L78.4224385,15.8687509 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M83.9764352,11.2515178 C86.4772336,10.5926577 87.8524044,10.3718409 89.2527127,10.3718409 C92.8809344,10.3718409 94.0352908,11.9929612 94.0352908,15.8132769 L94.0352908,23.5903494 L91.5614162,23.5903494 L91.5614162,21.9692291 C90.4070598,22.9045596 89.0875508,23.8380969 87.0822381,23.8380969 C84.1685302,23.8380969 82.68385,22.7106713 82.68385,19.9082684 C82.68385,17.1597236 84.4701346,15.7594187 88.0983563,15.7594187 C89.6099696,15.7594187 90.3532119,15.7594187 91.5614161,15.7863483 C91.5614161,13.2586202 91.0389972,12.5710348 88.8398031,12.5710348 C87.7141754,12.5710348 86.2851477,12.7900583 84.3893445,13.2586202 L83.9764352,11.2515178 Z M88.4017563,17.5439089 C86.0930529,17.5439089 84.9656296,17.9837491 84.9656296,19.6605232 C84.9656296,21.2008558 85.9278909,21.9961574 87.7967518,21.9961574 C89.1970601,21.9961574 90.5722308,21.1164794 91.5614161,20.0177802 L91.5614161,17.5439089 L88.4017563,17.5439089 L88.4017563,17.5439089 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M106.624382,21.8871843 C105.38744,23.0415384 104.288746,23.8386331 102.089552,23.8386331 C99.2871488,23.8386331 96.6481123,21.9410436 96.6481123,17.0507516 C96.6481123,12.2143167 98.7377969,10.3723783 101.787947,10.3723783 C103.656808,10.3723783 105.360517,11.1138206 106.624382,12.2951032 L106.624382,5.64545571 L109.098248,5.64545571 L109.098248,23.5908874 L106.624382,23.5908874 L106.624382,21.8871843 L106.624382,21.8871843 Z M106.624382,14.6594626 C105.470026,13.2860874 104.150508,12.4602674 102.585047,12.4602674 C100.522286,12.4602674 99.0950537,13.6433443 99.0950537,17.0507516 C99.0950537,20.4850851 100.852619,21.7776749 102.804065,21.7776749 C104.536493,21.7776749 105.579535,20.7328326 106.624382,19.5784786 L106.624382,14.6594626 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M124.655088,10.6472328 C123.116542,14.4675496 121.631862,18.2052829 119.98202,21.418802 C118.443473,24.3881647 116.547688,26.5586347 113.689633,27.6860603 L112.809948,25.5694472 C114.129466,24.9375144 115.201237,24.249929 116.190432,23.0955749 L110.969807,10.6472328 L113.551395,10.6472328 L116.931869,18.6720504 C117.235269,19.3865654 117.483017,20.0723553 117.728969,20.7042881 C119.186716,17.819302 120.312344,14.7691528 121.933466,10.6472328 L124.655088,10.6472328 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M153.859679,17.1875498 C153.859679,21.1730296 151.249371,23.8389946 147.319545,23.8389946 C143.416643,23.8389946 140.833268,21.1730296 140.833268,17.1875498 C140.833268,13.0925595 143.445371,10.3727375 147.346469,10.3727375 C151.249371,10.3727375 153.859679,13.0925595 153.859679,17.1875498 M151.441466,17.1875498 C151.441466,14.3564229 149.79341,12.4875562 147.346469,12.4875562 C144.901323,12.4875562 143.251481,14.356423 143.251481,17.1875498 C143.251481,19.9091661 144.872604,21.7223815 147.319545,21.7223815 C149.79341,21.7223815 151.441466,19.9091661 151.441466,17.1875498'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M159.139004,13.1474938 C159.826594,11.5263722 161.282546,10.3989502 163.014974,10.3989502 C163.48174,10.3989502 163.921582,10.3989502 164.663039,10.4815311 L164.497867,12.9284726 C163.839006,12.872819 163.399164,12.872819 162.930611,12.872819 C161.173046,12.872819 159.797866,14.0541014 159.139004,16.1150621 L159.139004,23.590529 L156.665139,23.590529 L156.665139,10.6197657 L159.139004,10.6197657 L159.139004,13.1474938 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M175.328132,21.8871843 C174.0912,23.0415384 172.992496,23.8386331 170.793302,23.8386331 C167.990899,23.8386331 165.351862,21.9410436 165.351862,17.0507516 C165.351862,12.2143167 167.441556,10.3723783 170.491707,10.3723783 C172.360558,10.3723783 174.064257,11.1138206 175.328132,12.2951032 L175.328132,5.64545571 L177.801998,5.64545571 L177.801998,23.5908874 L175.328132,23.5908874 L175.328132,21.8871843 Z M175.328132,14.6594626 C174.173776,13.2860874 172.854267,12.4602674 171.288797,12.4602674 C169.226045,12.4602674 167.798804,13.6433443 167.798804,17.0507516 C167.798804,20.4850851 169.556369,21.7776749 171.507816,21.7776749 C173.240243,21.7776749 174.283295,20.7328326 175.328132,19.5784786 L175.328132,14.6594626 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M192.562282,21.8595376 C190.775988,23.2885653 189.181808,23.8379172 186.846171,23.8379172 C183.300544,23.8379172 180.661508,21.8595376 180.661508,17.2151964 C180.661508,12.4326207 183.356179,10.3716601 186.95569,10.3716601 C190.555183,10.3716601 192.644858,12.6247145 192.727453,16.6658479 C192.727453,17.0231036 192.727453,17.2977784 192.644858,17.7914757 L183.025855,17.7914757 C183.24666,20.7321143 184.81213,21.8595376 186.95569,21.8595376 C188.686313,21.8595376 190.00584,21.4196997 191.655692,20.2653457 L192.562282,21.8595376 Z M190.528259,15.8687509 C190.336164,13.5869736 189.072289,12.3787626 186.95569,12.3787626 C185.004244,12.3787626 183.438773,13.3679524 183.081507,15.8687509 L190.528259,15.8687509 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M198.033518,13.1474938 C198.721108,11.5263722 200.17706,10.3989502 201.907701,10.3989502 C202.376254,10.3989502 202.816096,10.3989502 203.557534,10.4815311 L203.392363,12.9284726 C202.73352,12.872819 202.293659,12.872819 201.825106,12.872819 C200.067541,12.872819 198.69238,14.0541014 198.033518,16.1150621 L198.033518,23.590529 L195.559634,23.590529 L195.559634,10.6197657 L198.033518,10.6197657 L198.033518,13.1474938 L198.033518,13.1474938 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
                <path
                    d='M138.770332,26.7625779 L138.770332,24.6244207 L127.878485,24.6244207 L127.883862,24.3299984 C127.912591,21.8651038 130.17462,20.306818 132.5695,18.6551769 C135.037988,16.9532677 138.416667,14.6230189 138.416667,11.0289067 C138.416667,9.36649531 137.811662,7.96978036 136.666293,6.98956831 C135.562202,6.04167067 134.03802,5.54079195 132.257122,5.54079195 C129.921495,5.54079195 127.955675,6.25171533 125.550031,7.40427378 L126.80851,9.11695219 C128.727637,8.18341722 129.765301,7.65561059 131.831644,7.65561059 C133.899791,7.65561059 135.998444,8.64479929 135.998444,10.9858196 C135.998444,13.3268398 133.303765,15.2441798 131.127913,16.7845136 C128.271644,18.8041826 125.031203,21.0949375 125.031203,25.3461167 C125.031203,25.7518439 125.0689,26.2042505 125.147895,26.7625779 L138.770332,26.7625779 Z'
                    fill='#0A2433'
                    fillRule='nonzero'
                ></path>
            </g>
        </svg>
    );
}
