/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PropsWithChildren, ReactNode } from 'react';
import type { LoaderFunctionArgs } from 'react-router';
import {
    Links,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    isRouteErrorResponse,
    useLoaderData,
    useLocation,
    useRouteError,
} from 'react-router';
import styles from './css/tailwind.css?url';

import { todayCET } from './lib/util';
import i18next from './i18n/i18next.server';
import { Namespace, SupportedLanguage } from './i18n';
import { i18nCookie } from './i18n/i18next-cookie.server';
import { useChangeLanguage } from './i18n/util';
import 'react-toastify/dist/ReactToastify.css';
import { IStaticMethods } from 'preline/preline';
import { GeneralError } from './components/general-error.component';
import ToastProvider from './services/toast/toast.context';
import { paths } from './paths';
import { getConfigValue } from './config/config.server';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import * as Sentry from '@sentry/react';
import { getSessionData } from './session.server';
import { version } from '../package.json';
import ModalProvider, { ModalId } from './services/modal/modal.context';
import {
    ignoreErrorsFrontend,
    ignoreErrorsFullstack,
} from './sentry/ignore-errors.sentry';
import { usePrelineHs } from './hooks/use-preline-hs.hook';
import AppProvider, { useAppContext } from './app.context';
import Navbar from './components/navbar.component';

declare global {
    interface Window {
        HSStaticMethods: IStaticMethods;
    }
}

type LoaderData = {
    locale: string;
    returnLink: ReturnLink;
    sentryDsn: string;
    accountId?: string;
    appVersion: string;
    environment: string;
};

if (typeof window !== 'undefined') {
    import('preline/preline')
        .then((module) => {
            module.default;
        })
        .catch((err) => {
            console.error('Error loading preline:', err);
        });
}

type ReturnLink = { type: 'pos' | 'admin'; href: string };

export const links = () => {
    return [{ rel: 'stylesheet', href: styles }];
};

function getReturnLink(request: LoaderFunctionArgs['request']): ReturnLink {
    const parseUrl = (url: string): URL | null => {
        try {
            return url ? new URL(url) : null;
        } catch {
            return null;
        }
    };

    const referer = request.headers.get('Referer');
    const adminUrl = getConfigValue('admin.admin_url') || '';
    const posUrl = getConfigValue('pos.pos_url') || '';

    const referrerOriginUrl = parseUrl(referer || '');
    const adminOriginUrl = parseUrl(adminUrl || '');
    const posOriginUrl = parseUrl(posUrl || '');

    invariant(adminOriginUrl, 'Invalid url for environment variable ADMIN_URL');
    invariant(posOriginUrl, 'Invalid url for environment variable POS_URL');

    const whitelist: Record<string, ReturnLink> = {
        [adminOriginUrl.origin]: { type: 'admin', href: adminOriginUrl.origin },
        [posOriginUrl.origin]: { type: 'pos', href: posOriginUrl.origin },
    };

    return referrerOriginUrl && whitelist[referrerOriginUrl.origin]
        ? whitelist[referrerOriginUrl.origin]
        : { type: 'admin', href: adminOriginUrl.origin };
}

export async function loader({
    request,
}: LoaderFunctionArgs): Promise<Response> {
    try {
        const data = await getSessionData(request);
        // getLocale looks first for the search parameter "lng", then for the cookie.
        const locale = await i18next.getLocale(request);

        const environment = getConfigValue('environment.environment');

        const returnLink = getReturnLink(request);

        // set cookie to persist language
        // the i18nCookie.serialize() function uses btoa/atob to encode/decode the value of the cookie
        const serialized = await i18nCookie.serialize(locale);
        const cookie = {
            headers: { 'Set-Cookie': serialized },
        };

        // here we get the dsn from the environment variables and pass it to the client, because env variables should only live on the server side of remix
        // https://remix.run/docs/en/main/guides/envvars
        const sentryDsn = getConfigValue('sentry.sentry_dsn');

        return Response.json(
            {
                locale,
                returnLink,
                sentryDsn,
                accountId: data?.accountId,
                appVersion: version,
                environment,
            },
            cookie
        );
    } catch (err) {
        console.log('[DEBUG UNEXPECTED SERVER ERROR] root.tsx catch()', err);
        throw err;
    }
}

export const handle = {
    // In the handle export, we can add a i18n key with namespaces our route
    // will need to load. This key can be a single string or an array of strings.
    // TIP: In most cases, you should set this to your defaultNS from your i18n config
    // or if you did not set one, set it to the i18next default namespace "translation"
    i18n: [Namespace.COMMON],
};

export const meta = () => {
    return [{ title: `ready2order - Account` }];
};

function Document(props: { children: ReactNode; title?: string }) {
    return (
        <html lang='en'>
            <head>
                {props.title ? <title>{props.title}</title> : null}
                <meta charSet='utf-8' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <Meta />
                <Links />
            </head>
            <body>
                {props.children}
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    );
}

export function ErrorBoundary() {
    const error = useRouteError();
    console.log(
        '[DEBUG UNEXPECTED SERVER ERROR] root error boundary',
        JSON.stringify(error)
    );
    const ErrorWrapper = ({ children }: PropsWithChildren) => {
        return (
            <div
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: 'fit-content',
                    textAlign: 'center',
                    fontFamily: '"Open Sans", sans-serif',
                }}
            >
                {children}
            </div>
        );
    };

    if (isRouteErrorResponse(error)) {
        return (
            <Document title={error.statusText}>
                <ErrorWrapper>
                    <GeneralError
                        image={{ height: 350, width: 350 }}
                        data={error.data}
                        status={error.status}
                        statusText={error.statusText}
                    />
                </ErrorWrapper>
            </Document>
        );
    } else if (error && error instanceof Error) {
        // you only want to capture non 404-errors that reach the boundary
        Sentry.withScope((scope) => {
            scope.setContext('custom context - root error boundary', {
                errorMessage: error.message,
            });
            Sentry.captureException(error);
        });
        return (
            <ErrorWrapper>
                <GeneralError image={{ height: 350, width: 350 }} />
            </ErrorWrapper>
        );
    } else {
        return (
            <ErrorWrapper>
                <GeneralError image={{ height: 350, width: 350 }} />
            </ErrorWrapper>
        );
    }
}

function App() {
    const {
        locale,
        returnLink,
        sentryDsn,
        accountId,
        appVersion,
        environment,
    } = useLoaderData<LoaderData>();

    // initialize Sentry on client side, following this approach instead of sentrys setup guide:
    // https://github.com/remix-run/remix/discussions/6625
    // this way we can use environment variables (sentry dsn) which gets passed from server to client
    Sentry.init({
        dsn: sentryDsn,
        integrations: [Sentry.browserTracingIntegration()],
        release: `account-dashboard@${appVersion}-${environment}`,
        normalizeDepth: 6, // to show nested objects to a depth of 6 in the sentry ui
        environment,
        ignoreErrors: [...ignoreErrorsFullstack, ...ignoreErrorsFrontend],
        initialScope: {
            tags: {
                service_name: 'account-dashboard',
                stack: 'frontend',
            },
            user: {
                accountId: accountId,
            },
        },
        tracesSampleRate: environment === 'sandbox' ? 1 : 0.15, // in production trace only 15%, in sandbox 100% for debug reasons
    });

    const location = useLocation();

    const isAuthRoute = [paths.login(), paths.logout()].includes(
        location.pathname
    );

    // This hook will change the i18n instance language to the current locale
    // detected by the loader
    useChangeLanguage(locale as SupportedLanguage);

    usePrelineHs([location.pathname]);

    return (
        <html lang={locale}>
            <head>
                <meta charSet='utf-8' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1'
                />
                <Meta />
                <Links />
            </head>
            <body className='bg-grey-lighter dark:bg-slate-900'>
                <AppProvider
                    environment={environment}
                    appVersion={appVersion}
                >
                    <ToastProvider>
                        <ModalProvider modalId={ModalId.ROOT}>
                            {isAuthRoute ? (
                                <Auth />
                            ) : (
                                <Default returnLink={returnLink} />
                            )}
                        </ModalProvider>
                    </ToastProvider>
                </AppProvider>
                <ScrollRestoration />
                <Scripts />
            </body>
        </html>
    );
}

export default App;

function Default({ returnLink }: { returnLink: ReturnLink }) {
    const currentYear = todayCET().year();
    const { t } = useTranslation();
    const { appVersion } = useAppContext();

    const label: Record<ReturnLink['type'], string> = {
        ['pos']: t('nav.pos'),
        ['admin']: t('nav.admin'),
    };

    return (
        <>
            <header className='flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-grey-lightest p-2 border-b rounded text-sm py-2.5 sm:py-4 dark:bg-slate-900 dark:border-gray-700 sticky top-0 z-10'>
                <Navbar
                    href={returnLink.href}
                    label={label[returnLink.type]}
                />
            </header>
            <main
                id='content'
                role='main'
            >
                <div className='max-w-7xl mx-auto py-10 px-4 bg-grey-lighter sm:px-6 lg:px-8'>
                    <Outlet />
                </div>
            </main>
            <footer className='flex justify-between max-w-7xl mx-auto py-10 px-4 bg-grey-lighter sm:px-6 lg:px-8 border-t-2 '>
                <span className='text-grey-dark text-md font-normal'>
                    {'ready2order GmbH  ©' + ' ' + currentYear}
                </span>
                <span className='text-grey-dark text-md font-normal'>
                    {'v' + appVersion}
                </span>
            </footer>
        </>
    );
}

function Auth() {
    return <Outlet />;
}
